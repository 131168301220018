<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="testSmsSendPopup"
      header="SMS 전송 검증"
      allowDragging="true"
      showCloseIcon="true"
      isModal="true"
      width="100"
      v-bind="dialogProps"
      @close="onCloseTestSmsSendPopup"
    >
      <div id="testSmsSendPopup" class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section">
                  <div class="section-body" style="border: none; margin: -18px">
                    <ul class="lookupDetail-condition">
                      <li class="field">
                        <div class="title">수신번호</div>
                        <ul class="content">
                          <li class="item">
                            <component-telephone
                              ref="contactTel"
                              v-model="contactTel"
                              :max-length="11"
                            />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  :is-custom-shortcut-button="true"
                  shortcut-key="DailyCloseSendSmsPopup.shortcuts.sendSMS"
                  :shortcut="{key: 'F7'}"
                  @click.native="onSmsSendButtonClicked()"
              >
                전송
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onCloseTestSmsSendPopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped></style>

<script>
import { validateFormRefs } from "@/utils/formUtil";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { SHORTCUT_KEYS } from "@/utils/KeyboardUtil";
import ComponentTelephone from "@/components/ComponentTelephone";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "TestSmsSendPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: { ComponentTelephone, ErpButton },
  data() {
    return {
      contactTel: "",
      validateRefList: {
        contactTel: { required: true },
      },
    };
  },
  computed: {
    dialogProps() {
      return {
        animationSettings: { effect: "None" },
        position: { x: "center", y: "center" },
        visible: false,
      };
    },
    sendSmsButtonProps() {
      return {
        shortcutKey: "TestSmsSendPopup.shortcuts.sendSms",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F7,
        },
      };
    },
  },
  methods: {
    validateFormRefs,
    showPopup() {
      this.$refs.testSmsSendPopup.show();
    },
    onSmsSendButtonClicked() {
      if (!this.validateFormRefs(this.validateRefList)) {
        return;
      }
      this.$emit("smsSend", this.contactTel);
      this.$refs.testSmsSendPopup.hide();
    },
    onCloseTestSmsSendPopup() {
      this.$emit("popupClosed");
      this.$refs.testSmsSendPopup.hide();
    },
  },
};
</script>
